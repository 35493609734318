export const getAppleOsSystemStatus = (endpoint) => {
    const availableOsUpdates = endpoint?.availableOsUpdates;
    const osUpdateStatus = endpoint?.osUpdateStatus.find((osState) => osState);
    const OSUpdateVersion = endpoint?.availableOsUpdates?.find((version) => version.Version);

    if (availableOsUpdates) {
        if (osUpdateStatus?.Status !== 'Idle') {
            switch (osUpdateStatus?.Status) {
                case 'Downloading':
                    return {
                        name: 'Downloading OS update',
                        process: osUpdateStatus?.DownloadPercentComplete * 100,
                        tooltip: `${osUpdateStatus?.DownloadPercentComplete * 100}% Downloaded of ${OSUpdateVersion?.HumanReadableName}`,
                    };
                case 'DownloadFailed':
                    return {
                        name: 'OS download failed',
                        badgeColor: 'red',
                    };

                case 'DownloadRequiresComputer':
                    return {
                        name: 'Computer needed to download OS update',
                        badgeColor: 'amber',
                    };
                case 'DownloadInsufficientSpace':
                    return {
                        name: 'Not enough space to download OS update',
                        badgeColor: 'amber',
                    };
                case 'DownloadInsufficientPower':
                    return {
                        name: 'Not enough power to download OS update',
                        badgeColor: 'amber',
                    };
                case 'DownloadInsufficientNetwork':
                    return {
                        name: 'OS download failed due to network issues',
                        badgeColor: 'amber',
                    };
                case 'Installing':
                    return {
                        tooltip: `Installing ${OSUpdateVersion?.HumanReadableName}`,
                        name: 'Installing OS update',
                        badgeColor: 'blue',
                    };
                case 'InstallInsufficientSpace':
                    return {
                        name: 'Not enough space to install OS update',
                        badgeColor: 'amber',
                    };
                case 'InstallInsufficientPower':
                    return {
                        name: 'Not enough power to install OS update',
                        badgeColor: 'amber',
                    };
                case 'InstallPhoneCallInProgress':
                    return {
                        name: 'Installation failed due to phone call in progress',
                        badgeColor: 'amber',
                    };
                case 'InstallFailed':
                    return {
                        name: 'Installation failed due to unknown reasons',
                        badgeColor: 'red',
                    };
            }
        }
        if (osUpdateStatus?.IsDownloaded === true) {
            return {
                name: 'Ready to install OS update',
                badgeColor: 'amber',
                tooltip: `Ready to install ${OSUpdateVersion?.HumanReadableName}`,
            };
        }
        if (availableOsUpdates?.length === 0) {
            return {
                name: 'System is up-to-date',
                badgeColor: 'emerald',
                isUpToDate: true,
            };
        }
        if (availableOsUpdates?.some((availableOsUpdate) => availableOsUpdate.IsCritical)) {
            return {
                tooltip: `Update ${OSUpdateVersion?.HumanReadableName} is available`,
                name: 'Critical update available',
                badgeColor: 'red',
            };
        }
        return {
            tooltip: `Update ${OSUpdateVersion?.HumanReadableName} is available`,
            name: 'System update available',
            badgeColor: 'amber',
        };
    }
    if (!availableOsUpdates && endpoint.data?.IsSupervised === false) {
        return {
            tooltip: `Can't update OS on an unsupervised device`,
            name: 'System update not available',
            badgeColor: 'neutral',
        };
    }
    return {
        name: 'No system update available',
        badgeColor: 'neutral',
    };
};

export const getDepIconColor = (configuration) => {
    const depConfiguration = configuration?.depenrollment;
    if (configuration.isDefault === true) {
        return {
            color: 'success',
            tooltip: 'Default DEP configuration',
        };
    }
    if (depConfiguration && Object.keys(depConfiguration).length !== 0) {
        return {
            color: 'primary',
            tooltip: 'DEP is configured',
        };
    }
    return {
        color: 'disabled',
        tooltip: 'DEP is not configured',
    };
};
