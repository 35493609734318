import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import { Tooltip } from '../tooltip/tooltip';
import { TooltipProps } from '@capasystems/types';

const ellipsisClasses = {
    root: 'cs-ellipsis',
};

export type EllipsisProps = {
    children: React.ReactNode;
    tooltipProps?: Omit<TooltipProps, 'children' | 'content'> & { content?: string };
};

export const Ellipsis: React.FC<EllipsisProps> = ({ children, tooltipProps, ...otherProps }) => {
    const [content, setContent] = useState('');

    /* istanbul ignore next */
    const onMouseOver: React.MouseEventHandler<HTMLDivElement> = ({ currentTarget }) => {
        const hasEllipsis = currentTarget.offsetWidth < currentTarget.scrollWidth;
        setContent(hasEllipsis ? currentTarget.innerText : '');
    };

    return (
        <Tooltip
            dark
            extraPadding
            bold
            position={'top'}
            {...tooltipProps}
            content={content !== '' ? tooltipProps?.content || content : ''}
        >
            <Typography
                {...otherProps}
                classes={ellipsisClasses}
                variant="inherit"
                noWrap
                onMouseOver={onMouseOver}
                display="block"
                component="div"
            >
                {children}
            </Typography>
        </Tooltip>
    );
};

export default Ellipsis;
