import { useEffect, useRef } from 'react';

const useKeyPress = (callback, targetKeys) => {
    const pressedKeys = useRef([]); // Ref for keeping track of which keys are pressed

    useEffect(() => {
        const keydownHandler = ({ key }) => {
            if (pressedKeys.current.indexOf(key) < 0) {
                pressedKeys.current.push(key);
                let allPressed = targetKeys.every((targetKey) => pressedKeys.current.includes(targetKey));
                if (allPressed) {
                    window.event.preventDefault(); // Prevents opening a Save dialog in Windows.
                    window.event.stopPropagation();
                    callback();
                }
            }
        };

        const keyupHandler = ({ key }) => {
            pressedKeys.current = pressedKeys.current.filter((k) => k !== key);
        };

        const blurHandler = () => {
            pressedKeys.current = [];
        };

        window.addEventListener('keydown', keydownHandler);
        window.addEventListener('keyup', keyupHandler);
        window.addEventListener('blur', blurHandler); // Reset if user switches tab via ctrl + tab as we can't detect keyup during that time.

        return () => {
            window.removeEventListener('keydown', keydownHandler);
            window.removeEventListener('keyup', keyupHandler);
            window.removeEventListener('blur', blurHandler);
        };
    }, [callback, targetKeys]);

    return;
};

export { useKeyPress };
