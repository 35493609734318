import { isDefined, isUndefined } from '@capasystems/utils';
import { LEAF_OPERATOR, LEAF_TYPE, MONGO_DB, SUPPORTED_OPERATORS_FOR_LEAF_TYPE_SELECT, SUPPORTED_OPERATORS_FOR_LEAF_TYPE_STRING } from '@thirdparty/constants';

import dayjs from 'dayjs';

const stringToRegex = (inputString) => {
    /** Users can use "," to target multiple  */
    return inputString
        .split(',')
        .map((s) => {
            return s.trim().replace(/[^a-zA-Z0-9\s]/g, (specialChar) => {
                return `\\${specialChar}`;
            });
        })
        .join('|');
};

const stringHandler = ({ operator, value }) => {
    if (!SUPPORTED_OPERATORS_FOR_LEAF_TYPE_STRING.some((op) => op.id === operator.id)) {
        return ''; // Invalid operator passed.
    }
    if (operator.id === LEAF_OPERATOR.CONTAINS.id) {
        return {
            [MONGO_DB.REGEX]: `${stringToRegex(value)}`,
            $options: 'i',
        };
    }
    if (operator.id === LEAF_OPERATOR.STRING_NOT_CONTAINS.id) {
        return {
            [MONGO_DB.REGEX]: `${MONGO_DB.REGEX_PREFIX.STRING_NOT_CONTAINS}${stringToRegex(value)}${MONGO_DB.REGEX_SUFFIX.STRING_NOT_CONTAINS}`,
            $options: 'i',
        };
    }
    if (operator.id === LEAF_OPERATOR.STARTS_WITH.id) {
        return {
            [MONGO_DB.REGEX]: `${MONGO_DB.REGEX_PREFIX.STARTS_WITH}${stringToRegex(value)}${MONGO_DB.REGEX_SUFFIX.STARTS_WITH}`,
            $options: 'i',
        };
    }
    if (operator.id === LEAF_OPERATOR.ENDS_WITH.id) {
        return {
            [MONGO_DB.REGEX]: `${MONGO_DB.REGEX_PREFIX.ENDS_WITH}${stringToRegex(value)}${MONGO_DB.REGEX_SUFFIX.ENDS_WITH}`,
            $options: 'i',
        };
    }
    return {
        [operator.id]: value, // LEAF_OPERATOR.EQUAL, LEAF_OPERATOR.NOT_EQUAL.
    };
};

const selectHandler = ({ value, operator }) => {
    if (SUPPORTED_OPERATORS_FOR_LEAF_TYPE_SELECT.some((op) => op.id === operator.id)) {
        return {
            [operator.id]: value,
        };
    }
    return '';
};

const booleanHandler = ({ value }) => {
    return value;
};

const prepareMongoRange = ({ min, max }) => {
    if (min && max) {
        return { $gte: min, $lte: max };
    } else if (min) {
        return { $gte: min };
    } else if (max) {
        return { $lte: max };
    }
};

const numberHandler = ({ value, operator }) => {
    let toReturn = '';
    if (operator.id === LEAF_OPERATOR.RANGE.id) {
        const { min, max } = value;
        let newMin, newMax;
        if (min) {
            newMin = Number(min);
        }
        if (max) {
            newMax = Number(max);
        }
        toReturn = prepareMongoRange({ min: newMin, max: newMax });
    }
    return toReturn;
};

const dateHandler = ({ value, operator }) => {
    let toReturn = '';
    if (operator.id === LEAF_OPERATOR.RANGE.id) {
        const { min, max } = value;
        let newMin, newMax;
        if (min) {
            newMin = { $date: dayjs(new Date(min)).format('YYYY-MM-DD[T00:00:00Z]') };
        }
        if (max) {
            newMax = { $date: dayjs(new Date(max)).format('YYYY-MM-DD[T23:59:59Z]') };
        }
        toReturn = prepareMongoRange({ min: newMin, max: newMax });
    }
    return toReturn;
};

const dateSubtractHandler = (negate, leaf, mongoedLeafs, id, value, ignoreInventoryCheck) => {
    if (!ignoreInventoryCheck && (id.includes('hardwareInventory') || id.includes('softwareInventory') || id.includes('driverInventory'))) {
        let keyStart = '';
        let keyEnd = '';

        if (id.includes('hardwareInventory')) {
            const keySplit = id.split('.');
            const keySplitSliceEnd = keySplit.length === 2 ? 1 : 2;
            keyStart = keySplit.slice(0, keySplitSliceEnd).join('.');
            keyEnd = keySplit.slice(keySplitSliceEnd).join('.');
        } else {
            const keySplit = id.split('.');
            keyStart = keySplit.slice(0, 1).join('.');
            keyEnd = keySplit.slice(1).join('.');
        }
        const inner = {
            [LEAF_OPERATOR.GREATER_THAN.id]: [
                {
                    $size: {
                        $ifNull: [
                            {
                                $filter: {
                                    input: `$${keyStart}`,
                                    as: 'item',
                                    cond: {
                                        $gt: [
                                            '$$item.' + keyEnd,
                                            {
                                                $dateSubtract: {
                                                    startDate: '$$NOW',
                                                    unit: leaf.unit,
                                                    amount: Number(value),
                                                },
                                            },
                                        ],
                                    },
                                },
                            },
                            [],
                        ],
                    },
                },
                0,
            ],
        };
        if (isUndefined(mongoedLeafs[MONGO_DB.AND])) {
            mongoedLeafs[MONGO_DB.AND] = [];
        }
        if (negate[leaf.branchDependencies[0]]) {
            mongoedLeafs[MONGO_DB.AND].push({
                [MONGO_DB.EXPR]: {
                    [MONGO_DB.NOT]: {
                        ...inner,
                    },
                },
            });
        } else {
            mongoedLeafs[MONGO_DB.AND].push({
                [MONGO_DB.EXPR]: {
                    ...inner,
                },
            });
        }
    } else {
        const inner = {
            [LEAF_OPERATOR.GREATER_THAN.id]: [
                `$${id}`,
                {
                    [LEAF_OPERATOR.DATE_SUBTRACT.id]: {
                        startDate: '$$NOW',
                        unit: leaf.unit,
                        amount: Number(value),
                    },
                },
            ],
        };
        if (isUndefined(mongoedLeafs[MONGO_DB.AND])) {
            mongoedLeafs[MONGO_DB.AND] = [];
        }
        if (negate[leaf.branchDependencies[0]]) {
            mongoedLeafs[MONGO_DB.AND].push({
                [MONGO_DB.EXPR]: {
                    [MONGO_DB.NOT]: {
                        ...inner,
                    },
                },
            });
        } else {
            mongoedLeafs[MONGO_DB.AND].push({
                [MONGO_DB.EXPR]: {
                    ...inner,
                },
            });
        }
    }
};

/**
 *
 * @param {*} leafs
 * @param {{hardware: Boolean, software: Boolean}} negate
 * @returns {Object} mongoedLeafs
 */
export const leafsToMongo = (leafs, negate = {}) => {
    // This should look at leaf.operator. $in, $regex, $nin etc.
    const mongoedLeafs = {};
    if (leafs.length > 0) {
        leafs.forEach((leaf) => {
            const { value, operator, type, id, ignoreInventoryCheck = false } = leaf;
            let keyStart, keyEnd;
            if (operator?.id === LEAF_OPERATOR.DATE_SUBTRACT.id) {
                dateSubtractHandler(negate, leaf, mongoedLeafs, id, value, ignoreInventoryCheck);
            } else if (!ignoreInventoryCheck && (id.includes('hardwareInventory') || id.includes('dictionaries'))) {
                const keySplit = id.split('.');
                const keySplitSliceEnd = keySplit.length === 2 ? 1 : 2;
                keyStart = keySplit.slice(0, keySplitSliceEnd).join('.');
                keyEnd = keySplit.slice(keySplitSliceEnd).join('.');
                if (!mongoedLeafs[keyStart]) {
                    if (negate[leaf.branchDependencies[0]]) {
                        mongoedLeafs[keyStart] = {
                            [MONGO_DB.NOT]: {
                                [MONGO_DB.ELEM_MATCH]: {},
                            },
                        };
                    } else {
                        mongoedLeafs[keyStart] = {
                            [MONGO_DB.ELEM_MATCH]: {},
                        };
                    }
                }
            } else if (!ignoreInventoryCheck && (id.includes('softwareInventory') || id.includes('driverInventory'))) {
                const keySplit = id.split('.');
                keyStart = keySplit.slice(0, 1).join('.');
                keyEnd = keySplit.slice(1).join('.');
                if (!mongoedLeafs[keyStart]) {
                    if (negate[leaf.branchDependencies[0]]) {
                        mongoedLeafs[keyStart] = {
                            [MONGO_DB.NOT]: {
                                [MONGO_DB.ELEM_MATCH]: {},
                            },
                        };
                    } else {
                        mongoedLeafs[keyStart] = {
                            [MONGO_DB.ELEM_MATCH]: {},
                        };
                    }
                }
            }

            let filterToApply;

            if (type === LEAF_TYPE.STRING) {
                filterToApply = stringHandler({ value, operator });
            }
            if (type === LEAF_TYPE.SELECT && value.length > 0) {
                filterToApply = selectHandler({ value, operator });
            }
            if (type === LEAF_TYPE.BOOLEAN) {
                filterToApply = booleanHandler({ value, operator });
            }
            if (type === LEAF_TYPE.NUMBER) {
                filterToApply = numberHandler({ value, operator });
            }
            if (type === LEAF_TYPE.DATE && operator.id !== LEAF_OPERATOR.DATE_SUBTRACT.id) {
                filterToApply = dateHandler({ value, operator });
            }

            if (filterToApply !== undefined) {
                if (
                    ignoreInventoryCheck ||
                    (!id.includes('hardwareInventory') && !id.includes('softwareInventory') && !id.includes('driverInventory') && !id.includes('dictionaries'))
                ) {
                    if (negate[leaf.branchDependencies[0]]) {
                        mongoedLeafs[id] = {
                            [MONGO_DB.NOT]: filterToApply,
                        };
                    } else {
                        mongoedLeafs[id] = filterToApply;
                    }
                } else {
                    if (negate[leaf.branchDependencies[0]]) {
                        mongoedLeafs[keyStart][MONGO_DB.NOT][MONGO_DB.ELEM_MATCH][keyEnd] = filterToApply;
                    } else {
                        mongoedLeafs[keyStart][MONGO_DB.ELEM_MATCH][keyEnd] = filterToApply;
                    }
                }
            }
        });
    }
    return mongoedLeafs;
};

export const getDefaultQueryBuilderConfiguration = (leafProps = {}) => {
    const { id = 'search', ...rest } = leafProps;
    return {
        root: {
            gridItemColumns: 0,
            options: [{ id: 'core', name: 'core' }],
            children: {
                core: {
                    gridItemColumns: 3,
                    options: [{ id, name: id }],
                    children: null,
                },
            },
        },
        leafs: [
            {
                id,
                placeholder: 'Search',
                ...rest, // Place overridables above ...rest.
                type: LEAF_TYPE.STRING,
                operator: LEAF_OPERATOR.CONTAINS,
                defaultValue: '',
                locked: true,
                primary: true,
                branchDependencies: ['core', id],
            },
        ],
    };
};
const deepDestructExpr = (obj) => {
    const key = (obj['$ifNull'][0]['$filter']['input'] + '.' + obj['$ifNull'][0]['$filter']['cond']['$gt'][0].replace('$$item.', '')).replace('$', '');
    const value = obj['$ifNull'][0]['$filter']['cond']['$gt'][1];

    return [
        {
            key,
            value,
        },
        false,
    ];
};
const destructExpr = (obj = {}) => {
    let destruct;
    let negate = false;
    if (obj[MONGO_DB.EXPR]['$not']) {
        if (obj[MONGO_DB.EXPR]['$not']['$gt'][0]['$size']) {
            const tmpStruct = deepDestructExpr(obj[MONGO_DB.EXPR]['$not']['$gt'][0]['$size']);
            tmpStruct[1] = true;
            return tmpStruct;
        }
        destruct = obj[MONGO_DB.EXPR]['$not']['$gt'];
        negate = true;
    } else if (obj[MONGO_DB.EXPR]['$gt'][0]['$size']) {
        return deepDestructExpr(obj[MONGO_DB.EXPR]['$gt'][0]['$size']);
    } else {
        destruct = obj[MONGO_DB.EXPR]['$gt'];
    }
    const key = destruct[0].replace('$', '');
    const value = destruct[1];
    return [
        {
            key,
            value,
        },
        negate,
    ];
};

const flattenQueryBuilderObject = (obj = {}, res = {}, extraKey = '', negate = {}) => {
    for (const key in obj) {
        if (key === MONGO_DB.AND) {
            obj[MONGO_DB.AND].forEach((theExpr) => {
                const [destructed, exprNeg] = destructExpr(theExpr);
                res[destructed.key] = destructed.value;
                if (exprNeg) {
                    negate[destructed.key.split('.')[0]] = exprNeg;
                }
            });
        } else if (key === MONGO_DB.EXPR) {
            const [destructed, exprNeg] = destructExpr(obj);
            res[destructed.key] = destructed.value;
            if (exprNeg) {
                negate[destructed.key.split('.')[0]] = exprNeg;
            }
        } else if (typeof obj[key] !== 'object') {
            res[extraKey + key] = obj[key];
        } else if (obj[key]) {
            if (obj[key][MONGO_DB.NOT]) {
                obj.isNegateAble = true;
                negate[key.split('.')[0]] = true;
                flattenQueryBuilderObject(obj[key][MONGO_DB.NOT][MONGO_DB.ELEM_MATCH], res, `${extraKey}${key}.`, negate);
            } else if (obj[key][MONGO_DB.ELEM_MATCH]) {
                flattenQueryBuilderObject(obj[key][MONGO_DB.ELEM_MATCH], res, `${extraKey}${key}.`, negate);
            } else {
                res[extraKey + key] = obj[key];
            }
        } else {
            res[extraKey + key] = obj[key];
        }
    }
    return [res, negate];
};

export const queryBuilderQueryStringToUrlParams = (queryString, relatedQueryBuilderRef) => {
    const [queryObject, negate] = flattenQueryBuilderObject(JSON.parse(queryString));

    let urlParams = '';
    Object.entries(negate).forEach(([negateKey, negateValue]) => {
        urlParams += urlParams ? '&' : '';
        urlParams += `${negateKey}.negate=${negateValue}`;
    });
    // This should look at leaf.operator. $in, $regex, $nin etc. and not hardcoded like below.
    Object.entries(queryObject).forEach(([leafID, leafValue]) => {
        const foundLeaf = relatedQueryBuilderRef.leafs.find((leaf) => leaf.id === leafID);
        if (foundLeaf) {
            urlParams += urlParams ? '&' : '';
            const encodedLeafId = encodeURIComponent(foundLeaf.id);
            if (foundLeaf.type === LEAF_TYPE.STRING) {
                let groomedValue = '';
                let operatorID = '';
                if (isDefined(leafValue[MONGO_DB.REGEX])) {
                    const savedValue = leafValue[MONGO_DB.REGEX];
                    if (savedValue.startsWith(MONGO_DB.REGEX_PREFIX.STRING_NOT_CONTAINS) && savedValue.endsWith(MONGO_DB.REGEX_SUFFIX.STRING_NOT_CONTAINS)) {
                        groomedValue = savedValue.slice(
                            MONGO_DB.REGEX_PREFIX.STRING_NOT_CONTAINS.length,
                            MONGO_DB.REGEX_SUFFIX.STRING_NOT_CONTAINS.length * -1
                        );
                        operatorID = LEAF_OPERATOR.STRING_NOT_CONTAINS.id;
                    } else if (savedValue.startsWith(MONGO_DB.REGEX_PREFIX.STARTS_WITH) && savedValue.endsWith(MONGO_DB.REGEX_SUFFIX.STARTS_WITH)) {
                        groomedValue = savedValue.slice(MONGO_DB.REGEX_PREFIX.STARTS_WITH.length, MONGO_DB.REGEX_SUFFIX.STARTS_WITH.length * -1);
                        operatorID = LEAF_OPERATOR.STARTS_WITH.id;
                    } else if (savedValue.startsWith(MONGO_DB.REGEX_PREFIX.ENDS_WITH) && savedValue.endsWith(MONGO_DB.REGEX_SUFFIX.ENDS_WITH)) {
                        groomedValue = savedValue.slice(MONGO_DB.REGEX_PREFIX.ENDS_WITH.length, MONGO_DB.REGEX_SUFFIX.ENDS_WITH.length * -1);
                        operatorID = LEAF_OPERATOR.ENDS_WITH.id;
                    } else {
                        groomedValue = savedValue;
                        operatorID = LEAF_OPERATOR.CONTAINS.id;
                    }
                } else if (isDefined(leafValue[LEAF_OPERATOR.EQUAL.id])) {
                    operatorID = LEAF_OPERATOR.EQUAL.id;
                    groomedValue = leafValue[operatorID];
                } else if (isDefined(leafValue[LEAF_OPERATOR.NOT_EQUAL.id])) {
                    operatorID = LEAF_OPERATOR.NOT_EQUAL.id;
                    groomedValue = leafValue[operatorID];
                }
                urlParams += `${encodedLeafId}=${encodeURIComponent(groomedValue.replace(/(?<!\\)\|/g, ',').replace(/\\/g, ''))}`; // First replace pipes without a leading '\', ie. commas entered by the user. Then replace all our appended '\'.
                urlParams += '&';
                urlParams += `${encodedLeafId}.operator=${operatorID}`;
            } else if (foundLeaf.type === LEAF_TYPE.SELECT) {
                if (isDefined(leafValue[LEAF_OPERATOR.ANY_IN_ARRAY.id])) {
                    urlParams += `${encodedLeafId}=${leafValue[LEAF_OPERATOR.ANY_IN_ARRAY.id].map(encodeURIComponent).join()}`;
                    urlParams += '&';
                    urlParams += `${encodedLeafId}.operator=${LEAF_OPERATOR.ANY_IN_ARRAY.id}`;
                } else if (isDefined(leafValue[LEAF_OPERATOR.ALL_IN_ARRAY.id])) {
                    urlParams += `${encodedLeafId}=${leafValue[LEAF_OPERATOR.ALL_IN_ARRAY.id].map(encodeURIComponent).join()}`;
                    urlParams += '&';
                    urlParams += `${encodedLeafId}.operator=${LEAF_OPERATOR.ALL_IN_ARRAY.id}`;
                } else if (isDefined(leafValue[LEAF_OPERATOR.NONE_IN_ARRAY.id])) {
                    urlParams += `${encodedLeafId}=${leafValue[LEAF_OPERATOR.NONE_IN_ARRAY.id].map(encodeURIComponent).join()}`;
                    urlParams += '&';
                    urlParams += `${encodedLeafId}.operator=${LEAF_OPERATOR.NONE_IN_ARRAY.id}`;
                }
            } else if (foundLeaf.type === LEAF_TYPE.NUMBER) {
                urlParams += `${encodedLeafId}.min=${leafValue[LEAF_OPERATOR.GREATER_THAN_OR_EQUAL.id] || ''}`;
                urlParams += '&';
                urlParams += `${encodedLeafId}.max=${leafValue[LEAF_OPERATOR.LESS_THAN_OR_EQUAL.id] || ''}`;
                // Missing LEAF_OPERATOR handling here.
                // LEAF_OPERATOR.RANGE
                // LEAF_OPERATOR.LESS_THAN_OR_EQUAL
                // LEAF_OPERATOR.EQUAL
                // LEAF_OPERATOR.NOT_EQUAL
                // LEAF_OPERATOR.GREATER_THAN
                // LEAF_OPERATOR.GREATER_THAN_OR_EQUAL
                // LEAF_OPERATOR.LESS_THAN
                // LEAF_OPERATOR.LESS_THAN_OR_EQUAL
            } else if (foundLeaf.type === LEAF_TYPE.DATE) {
                if (leafValue[LEAF_OPERATOR.DATE_SUBTRACT.id]) {
                    urlParams += `${encodedLeafId}.operator=${LEAF_OPERATOR.DATE_SUBTRACT.id}&${encodedLeafId}.unit=${
                        leafValue[LEAF_OPERATOR.DATE_SUBTRACT.id]['unit']
                    }&${encodedLeafId}=${leafValue[LEAF_OPERATOR.DATE_SUBTRACT.id]['amount']}`;
                } else {
                    if (isDefined(leafValue[LEAF_OPERATOR.GREATER_THAN_OR_EQUAL.id])) {
                        urlParams += `${encodedLeafId}.min=${dayjs(leafValue[LEAF_OPERATOR.GREATER_THAN_OR_EQUAL.id]['$date']).format('YYYY-MM-DD') || ''}`;
                    } else {
                        urlParams += `${encodedLeafId}.min=`;
                    }
                    urlParams += '&';
                    if (isDefined(leafValue[LEAF_OPERATOR.LESS_THAN_OR_EQUAL.id])) {
                        urlParams += `${encodedLeafId}.max=${dayjs(leafValue[LEAF_OPERATOR.LESS_THAN_OR_EQUAL.id]['$date']).format('YYYY-MM-DD') || ''}`;
                    } else {
                        urlParams += `${encodedLeafId}.max=`;
                    }
                    urlParams += '&';
                    // Missing LEAF_OPERATOR handling here.
                    // LEAF_OPERATOR.RANGE
                    // LEAF_OPERATOR.LESS_THAN_OR_EQUAL
                    // LEAF_OPERATOR.EQUAL
                    // LEAF_OPERATOR.NOT_EQUAL
                    // LEAF_OPERATOR.GREATER_THAN
                    // LEAF_OPERATOR.GREATER_THAN_OR_EQUAL
                    // LEAF_OPERATOR.LESS_THAN
                    // LEAF_OPERATOR.LESS_THAN_OR_EQUAL
                }
            } else {
                urlParams += `${encodedLeafId}=${leafValue}`; // Boolean.
            }
        }
    });
    return urlParams;
};
