export const convertCapapacksPlatform = (platform) => {
    switch (platform.toLocaleLowerCase()) {
        case 'both':
            return 'Auto';
        default:
            return platform;
    }
};

export const convertCapapacksLanguage = (language) => {
    switch (language.toLocaleLowerCase()) {
        case 'neutral':
            return 'System language';
        default:
            return language;
    }
};
