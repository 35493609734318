/* istanbul ignore file */
import axios from 'axios';
import Qs from 'qs';
// import {
//     cacheAdapterEnhancer,
//     throttleAdapterEnhancer,
// } from 'axios-extensions'; /** https://github.com/kuitos/axios-extensions */
// import LRUCache from 'lru-cache';

// const ONE_SECOND = 1000;
// const ONE_MINUTE = ONE_SECOND * 60;
const CONNECTION_TIMED_OUT = 'ECONNABORTED';
const CONNECTION_CLOSED_STATUS_CODE = 499; // Client closed the request before the server could send a response.
// const DEFAULT_CACHE = new LRUCache({
//     max: 500,
//     ttl: ONE_MINUTE
// });
// const CACHE_FLAG = 'cache';
const BASE_URL = '/api/';
const DEFAULT_HEADERS = { 'cache-control': 'no-cache' };
// const CACHE_ADAPTER_ENHANCER_PARAMS = {
//     enabledByDefault: false,
//     defaultCache: DEFAULT_CACHE,
//     cacheFlag: CACHE_FLAG, // Use the 'cache: true' flag to enable caching for more than 1 second, ie. throttleAdapterEnhancerParams.threshold. Caching period is defined by the threshold in throttleAdapterEnhancerParams.
// };
// const THROTTLE_ADAPTER_ENHANCER_PARAMS = {
//     threshold: ONE_SECOND,
// };
// const ADAPTER = throttleAdapterEnhancer(
//     cacheAdapterEnhancer(axios.defaults.adapter, CACHE_ADAPTER_ENHANCER_PARAMS),
//     THROTTLE_ADAPTER_ENHANCER_PARAMS
// );

axios.defaults.baseURL = BASE_URL;
axios.defaults.headers = DEFAULT_HEADERS;
// axios.defaults.adapter = ADAPTER;
axios.defaults.withCredentials = true;
axios.defaults.paramsSerializer = {
    serialize: (params) => {
        return Qs.stringify(params, { arrayFormat: 'repeat' }); /** Format array params like params: {ids: [1,2]} to the ?ids=1&ids=2 format */
    },
};

/** Intercept the response and streamline it. */
axios.interceptors.response.use(
    (response) => {
        // Received 2xx Success.
        const { data, ...details } = response;
        return {
            data,
            details,
        };
    },
    (errorDetails) => {
        // An error occurred.
        if (axios.isCancel(errorDetails)) {
            return Promise.reject({
                data: null, // No data received.
                status: {
                    code: CONNECTION_CLOSED_STATUS_CODE,
                    text: 'Request cancelled by the client',
                    message: 'Request cancelled by the client',
                    cancelled: true,
                    timedOut: false,
                },
                details: errorDetails,
            });
        }
        const { response, message, code, ...otherErrorDetails } = errorDetails;
        const timedOut = code === CONNECTION_TIMED_OUT;
        if (response) {
            // The server responded.
            const { data, status, statusText, ...details } = response;
            return Promise.reject({
                data, // Contains the data from server.
                status: {
                    code: status,
                    text: statusText,
                    message,
                    cancelled: false,
                    timedOut,
                },
                details,
            });
        }

        // The server did not respond. Could be due to request timeout, request cancellation or a network error.
        const statusCode = timedOut ? CONNECTION_CLOSED_STATUS_CODE : 400;
        const statusText = timedOut ? 'Request timed out' : 'Bad Request';
        return Promise.reject({
            data: null, // No data received.
            status: {
                code: statusCode,
                text: statusText,
                message: message ? message : statusText,
                cancelled: false,
                timedOut,
            },
            details: otherErrorDetails,
        });
    }
);

export { axios as api };
