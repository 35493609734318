import { windowsApplication } from '@thirdparty/constants';

const windowsApplicationUtils = {
    fileUploadCompleted: (application) => {
        if (application.type === windowsApplication.type.customApp.id) {
            return application.fileInfo?.fileUploaded === true; // Do not display custom apps until content is uploaded
        }
        return true;
    },
    fileUploadCompletedOrFailed: (application) => {
        if (application.type === windowsApplication.type.customApp.id) {
            return application.fileInfo?.fileUploaded === true || application.fileInfo?.failed === true;
        }
        return true;
    },
    fileUploadInProgress: (application) => {
        if (application.type === windowsApplication.type.customApp.id) {
            return application.fileInfo?.fileUploaded === false && application.fileInfo?.failed !== true;
        }
        return false;
    },
};

export { windowsApplicationUtils };
