import dayjs from 'dayjs';
import dayjsRelativeTimePlugin from 'dayjs/plugin/relativeTime';
import dayjsUtcPlugin from 'dayjs/plugin/utc';
import dayjsTimezonePlugin from 'dayjs/plugin/timezone';
import dayjsMinMaxPlugin from 'dayjs/plugin/minMax';
import dayjsLocalizedFormatPlugin from 'dayjs/plugin/localizedFormat';
import dayjsDurationPlugin from 'dayjs/plugin/duration';

dayjs.extend(dayjsRelativeTimePlugin);
dayjs.extend(dayjsUtcPlugin);
dayjs.extend(dayjsTimezonePlugin);
dayjs.extend(dayjsMinMaxPlugin);
dayjs.extend(dayjsLocalizedFormatPlugin);
dayjs.extend(dayjsDurationPlugin);

let defaultDateFormat = 'DD-MM-YYYY';
let defaultTimeFormat = 'DD-MM-YYYY HH:mm';
let detailedTimeFormat = 'DD-MM-YYYY HH:mm:ss';

export function formatTimestamp(timestamp, includeSeconds = false) {
    if (includeSeconds) {
        return dayjs(timestamp).format(detailedTimeFormat);
    }
    return dayjs(timestamp).format(defaultTimeFormat);
}

export function formatDate(date, hideHoursAndMinutes = false) {
    const dayjsObject = dayjs(date);
    if (hideHoursAndMinutes || (dayjsObject.hour() === 0 && dayjsObject.minute() === 0)) {
        return dayjsObject.format(defaultDateFormat);
    }
    return dayjsObject.format(defaultTimeFormat);
}

export function formatPeriod(startDate, endDate) {
    // Needs to be made into a function that formats the period into outlook calender format.
    return `${dayjs(startDate).format(defaultDateFormat)} - ${dayjs(endDate).format(defaultDateFormat)}`;
}

/** Accepts a object like { start: Number, end: Number } */
export function formatPeriodDetailed({ start, end }) {
    const dayjsStartDateObject = dayjs(start);
    const dayjsEndDateObject = dayjs(end);
    const minuteDiff = dayjsEndDateObject.diff(dayjsStartDateObject, 'minute');
    const yearPart = dayjsStartDateObject.diff(dayjs(), 'year') > 0 ? ' YYYY' : '';
    if (minuteDiff === 0) {
        return dayjsEndDateObject.format(`dddd DD MMM${yearPart} HH:mm`);
    }
    const startDateIsMidnight = dayjsStartDateObject.hour() === 0 && dayjsStartDateObject.minute() === 0;
    const isMidnightToMidnight = startDateIsMidnight && minuteDiff === 1440;
    if (isMidnightToMidnight) {
        return dayjsStartDateObject.format(`dddd DD MMMM${yearPart}`);
    }
    const isSameDay = dayjsStartDateObject.isSame(dayjsEndDateObject, 'day');
    if (isSameDay) {
        return `${dayjsStartDateObject.format(`dddd DD MMM${yearPart} HH:mm`)} - ${dayjsEndDateObject.format('HH:mm')} `;
    }
    const endDateIsMidnight = dayjsEndDateObject.hour() === 0 && dayjsEndDateObject.minute() === 0;
    return `${dayjsStartDateObject.format(startDateIsMidnight ? `DD MMM${yearPart}` : `DD MMM${yearPart} HH:mm`)} - ${dayjsEndDateObject.format(
        endDateIsMidnight ? `DD MMM${yearPart}` : `DD MMM${yearPart} HH:mm`
    )}`;
}

// Returns the number of milliseconds since the Unix Epoch of the Day.js object.
export function now() {
    return dayjs().valueOf();
}

// Returns the number of seconds since the Unix Epoch of the Day.js object.
export function epoch() {
    return dayjs().unix();
}

export function changeTimeFormats(newDefaultTimeFormat, newDetailedTimeFormat) {
    defaultTimeFormat = newDefaultTimeFormat;
    detailedTimeFormat = newDetailedTimeFormat;
}

export const convertLocalTimestampToUtc = (timeInMili) => {
    return timeInMili - dayjs().utcOffset() * 60 * 1000;
};
