export const stripRequiredEdges = (edges) => edges.filter((n) => !n.source.includes('required_schedule'));
export const stripRequiredNodes = (nodes, edges) =>
    nodes.filter((n) => {
        let shouldInclude = !n.id.includes('required');
        const edgeIndex = edges.findIndex((e) => e.target === n.id);
        if (edgeIndex < 0) {
            shouldInclude = false;
            const edgeFrom = edges.findIndex((e) => e.source === n.id);
            if (edgeFrom > 0) {
                edges.splice(edgeFrom, 1);
            }
        }
        return shouldInclude;
    });
