import { LayoutRow, Page, Tab, Tabs } from '@capasystems/ui';
import { useEffect, useRef, useState } from 'react';
import { AndroidEndpointAppliedConfigurationsTab } from './AndroidEndpointAppliedConfigurationsTab';
import { AndroidEndpointAssignedConfigurationsTab } from './AndroidEndpointAssignedConfigurationsTab';

export const AndroidConfigurationsWrapper = ({ endpoint }) => {
    const queryBuilderContainerRef = useRef(null);

    const [initialized, setInitialized] = useState(false);
    const [selectedTab, setSelectedTab] = useState('assigned');

    useEffect(() => {
        setInitialized(true); // queryBuilderContainerRef is set now.
    }, []);

    const onTabChange = (e, selectedTabId) => {
        setSelectedTab(selectedTabId);
    };

    const tabClassName = 'tw-font-semibold tw-min-w-0 lg:tw-px-8';

    return (
        <Page title="Endpoints · Configurations">
            <div className="tw-grid tw-h-full tw-grid-rows-auto-1fr tw-gap-4 tw-py-4">
                <LayoutRow
                    verticalAlign="center"
                    align="space-between"
                >
                    <Tabs
                        value={selectedTab}
                        onChange={onTabChange}
                        pills
                    >
                        <Tab
                            label="Assigned"
                            value="assigned"
                            disableRipple
                            className={tabClassName}
                        />
                        <Tab
                            label="Applied"
                            value="applied"
                            disableRipple
                            className={tabClassName}
                        />
                    </Tabs>
                    <div ref={queryBuilderContainerRef} />
                </LayoutRow>
                {initialized && selectedTab === 'assigned' && (
                    <AndroidEndpointAssignedConfigurationsTab
                        endpoint={endpoint}
                        portalContainer={queryBuilderContainerRef}
                    />
                )}
                {initialized && selectedTab === 'applied' && (
                    <AndroidEndpointAppliedConfigurationsTab
                        endpoint={endpoint}
                        portalContainer={queryBuilderContainerRef}
                    />
                )}
            </div>
        </Page>
    );
};
