import { formatTimestamp, now } from '@capasystems/utils';
import cronParser from 'cron-parser';
import cronstrue from 'cronstrue/i18n';
import dayjs from 'dayjs';

export const cronExtractor = (cron, startDate = null, endDate = null) => {
    try {
        const interval = cronParser.parseExpression(cron, {
            currentDate:
                dayjs(startDate).startOf('day').valueOf() >= now() ? dayjs(startDate).subtract(1, 'day').endOf('day').toISOString() : dayjs().toISOString(),
            endDate: endDate ? dayjs(endDate).endOf('day').toISOString() : undefined,
        });
        const nextRuns = [];
        interval.iterate(10, (d) => nextRuns.push(formatTimestamp(d.toString())));
        const formattedStartDate = dayjs(startDate).format('DD-MM-YYYY');
        const formattedEndDate = dayjs(endDate).format('DD-MM-YYYY');
        if (interval.hasNext()) {
            nextRuns.push('...');
            if (endDate) {
                nextRuns.push(formattedEndDate);
            }
        } else if (nextRuns.length === 0) {
            nextRuns.push(`The workflow will never run between ${formattedStartDate} and ${formattedEndDate}`);
        }
        return {
            text: cronstrue.toString(cron, {
                use24HourTimeFormat: true,
                locale: 'en',
                verbose: true,
            }),
            nextRuns,
            errorMessage: null,
        };
    } catch ({ message }) {
        return {
            text: '',
            nextRuns: [],
            errorMessage: message,
        };
    }
};
