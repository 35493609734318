import { inRange, numberFormat } from '@capasystems/utils';
import { NOT_AVAILABLE } from '@thirdparty/constants';
import classnames from 'classnames';
import languageNames from './language-names';

const formatUnit = ({ value, powersOf1000 = false, units, decimalPoints = 0, asText = false }) => {
    let result;
    if (!+value) {
        const tempValue = value ?? NOT_AVAILABLE;
        result = {
            value: tempValue,
            unit: tempValue === NOT_AVAILABLE ? '' : units[0],
        };
    } else {
        const threshold = powersOf1000 ? 1000 : 1024;
        const unitIndex = value < threshold ? 0 : Math.floor(Math.log(value) / Math.log(threshold));
        result = {
            value: parseFloat((value / Math.pow(threshold, unitIndex)).toFixed(decimalPoints)),
            unit: units[unitIndex],
        };
    }
    if (asText) {
        return `${result.value} ${result.unit}`;
    }
    return result;
};

export const formatPercentage = ({ value, decimalPoints = 1, asText = false }) => {
    const tempValue = numberFormat(value ?? NOT_AVAILABLE, decimalPoints);
    let result = {
        value: tempValue,
        unit: tempValue === NOT_AVAILABLE ? '' : '%',
    };
    if (asText) {
        return `${result.value} ${result.unit}`;
    }
    return result;
};

export const formatMilliseconds = ({ value, asText = false }) => {
    return formatUnit({
        value,
        decimalPoints: value < 1 || inRange(value, 1000, 10000) ? 1 : 0,
        asText,
        powersOf1000: true,
        units: ['ms', 's'],
    });
};

export const formatBytes = ({ value, decimalPoints = 0, asText = false }) => {
    return formatUnit({
        value,
        decimalPoints,
        asText,
        powersOf1000: false,
        units: ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
    });
};

export const formatBytesToGigabytes = (bytes) => {
    if (!bytes) {
        return 0;
    }
    return bytes / Math.pow(1024, 3);
};

export const formatBitsPerSecond = ({ value, decimalPoints = 0, asText = false }) => {
    return formatUnit({
        value,
        decimalPoints,
        asText,
        powersOf1000: true,
        units: ['bps', 'Kbps', 'Mbps', 'Gbps'],
    });
};

export const formatBytesPerSecond = ({ value, decimalPoints = 0, asText = false }) => {
    return formatUnit({
        value,
        decimalPoints,
        asText,
        powersOf1000: false,
        units: ['Bps', 'KBps', 'MBps', 'GBps'],
    });
};

export const formatHertz = ({ value, decimalPoints = 2, asText = false }) => {
    return formatUnit({
        value,
        decimalPoints,
        asText,
        powersOf1000: true,
        units: ['Hz', 'KHz', 'MHz', 'GHz'],
    });
};

export const getLanguageName = (isoLanguageCode) => {
    try {
        return languageNames.of(isoLanguageCode);
    } catch {
        return isoLanguageCode;
    }
};

export const getPlatformName = (bit) => `${bit}-bit`;

export const getSeverityClassName = (severity, asBackground = true) => {
    if (asBackground) {
        return classnames({
            // 'tw-bg-blue-900 tw-text-blue-100': severity === 0, // None
            'tw-bg-blue-50 tw-text-blue-700': severity === 0, // None
            'tw-bg-green-500 tw-text-green-100': severity === 1, // Low
            'tw-bg-yellow-50 tw-text-yellow-700': severity === 2, // Medium
            'tw-bg-orange-500 tw-text-orange-100': severity === 4, // High
            'tw-bg-red-500 tw-text-red-100': severity === 8, // Critical
        });
    }
    return classnames({
        'tw-text-blue-500': severity === 0, // None
        'tw-text-green-500': severity === 1, // Low
        'tw-text-yellow-500': severity === 2, // Medium
        'tw-text-orange-500': severity === 4, // High
        'tw-text-red-500': severity === 8, // Critical
    });
};

export const getSeverityDescription = (severity) => {
    switch (severity) {
        case 0:
            return 'None';
        case 1:
            return 'Low';
        case 2:
            return 'Medium';
        case 4:
            return 'High';
        case 8:
            return 'Critical';
        default:
            return 'Unknown';
    }
};

export const highchartsTooltipHeadingAndText = (header, text, addMarginTop = true) => {
    return `
        <div class="tw-text-tiny tw-block ${addMarginTop ? 'tw-mt-2' : 'tw-mt-0'}">
            ${header}
            <br />
            <span class="tw-text-xs tw-font-semibold">${text}</span>
        </div>
    `;
};
